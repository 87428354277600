import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

function LoginPage() {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Здесь вы можете добавить логику проверки введенных данных
    if (username === 'admin1' && password === 'pass1234') {
      // Аутентификация прошла успешно
      console.log('Authentication successful');
      // Установка значения аутентификации в localStorage
      localStorage.setItem('authenticated', 'true');
      // Перенаправление на страницу "admin"
      history.push('/admin');
    } else {
      // Неправильные учетные данные, отобразить ошибку
      console.log('Неправильные учетные данные');
      alert('Неправильные учетные данные'); // Display an error message
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="w-1/3 p-4 bg-gray-200 rounded-md">
        <h2 className="text-center mb-4 text-2xl font-bold">Административная панель - Вход</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="username" className="block font-medium">Логин:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={handleUsernameChange}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div>
            <label htmlFor="password" className="block font-medium">Пароль:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Войти
          </button>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
