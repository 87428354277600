import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from './Card';
import './Card.css';


const ZERO_SHIFT = 0;
const WEEK_SHIFT = 7;

const format_date = (date) => {
  const dateFormat = { day: "2-digit", month: "2-digit", year: "numeric" };
  return date.toLocaleDateString("ru-RU", dateFormat).replaceAll(".", "_"); 
}

const get_date = (thisDate, shift) => {
   const friday = 5;
   const thisDay = thisDate.getDay();

   if (thisDay == 5) {
     const CALCULATION = thisDate.getDate() - shift;
     let date = new Date(thisDate.setDate(CALCULATION));
     return format_date(date);
    }

   if (thisDay < friday) {
      const CALCULATION = thisDate.getDate() - (thisDay + 2) - shift;
      let date = new Date(thisDate.setDate(CALCULATION));
      return format_date(date);
   }  else {
        const difference = thisDay - friday - shift; 
        let date = new Date(thisDate.setDate(thisDate.getDate() - difference));
        return format_date(date)
    }
}


function RecentFriday() {
  const [fridayData, setFridayData] = useState(null);

  useEffect(() => {
    const fetchFridayData = async () => {
      const formattedDate = get_date(new Date(), WEEK_SHIFT);

      try {
        const response = await axios.get(`https://total93admin.com/get_all_events_for_jackpot/${formattedDate}`, {
          headers: {
            Authorization: 'Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd', // Replace with your authorization token
          },
        });

        setFridayData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchFridayData();
  }, []);

  return (
    <div>
      {Array.isArray(fridayData) ? (
        fridayData.map((event) => (
          <Card key={event.id} data={event} />
        ))
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default RecentFriday;