import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Button from '@mui/material/Button';
import axios from 'axios';

const drawerWidth = 240;


const toFixed = (data, num) => {
  try {
    return data.toFixed(num)
  } catch {
    return data
  }
}
//fetchData(url, setPredictionData_s_f);

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


// ----------------------------------    EXPORT DATA BY FUNCTIONS.  ------------------------------- //

export default function Admin() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [spamData, setSpamData] = React.useState([]);
  const [predictionData, setPredictionData] = React.useState([]);

  const [predictionData_single, setPredictionData_single] = React.useState([]); // single events
  const [predictionData_single_past, setPredictionData_single_past] = React.useState([]); // single events past

  const [predictionData_hot, setPredictionData_hot] = React.useState([]); // hot events
  const [predictionData_hot_past, setPredictionData_hot_past] = React.useState([]); // hot events past

  const [predictionData_gold, setPredictionData_gold] = React.useState([]); // gold events
  const [predictionData_gold_past, setPredictionData_gold_past] = React.useState([]); // gold events past


  const [predictionData_dg, setPredictionData_dg] = React.useState([]); // double gold events
  const [predictionData_dg_past, setPredictionData_dg_past] = React.useState([]); // double gold events past

  const [predictionData_jackpot, setPredictionData_jackpot] = React.useState([]);

  const [showPredictionsOnly, setShowPredictionsOnly] = React.useState(false);
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const fetchData = (url, setStateFunc) => {
    setStateFunc([])
    axios.get(url, {
      headers: {
        Authorization: 'Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd' // Замените на ваш токен
      }
    })
      .then(response => {
        console.log(response.data);
        setStateFunc(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const handleDrawerClick = () => {
    fetchData('/api/getSpamData', setSpamData);
  };

  const handleSortById = () => {
    const sortedById = [...spamData].sort((a, b) => a.id - b.id);
    setSpamData(sortedById);
  };

  const handleSortByDate = () => {
    const sortedByDate = [...spamData].sort((a, b) => {
      const dateA = new Date(
        parseInt(a.date.slice(6)),
        parseInt(a.date.slice(3, 5)) - 1,
        parseInt(a.date.slice(0, 2))
      );
      const dateB = new Date(
        parseInt(b.date.slice(6)),
        parseInt(b.date.slice(3, 5)) - 1,
        parseInt(b.date.slice(0, 2))
      );
      return dateB - dateA; // Обратная сортировка
    });
    setSpamData(sortedByDate);
  };

  const handleSortByRez1Score = () => {
    const sortedByRez1Score = [...spamData].sort((a, b) => b.rez_1_score - a.rez_1_score); // Обратная сортировка
    setSpamData(sortedByRez1Score);
  };

  const handleSortByRez3Score = () => {
    const sortedByRez3Score = [...spamData].sort((a, b) => b.rez_3_score - a.rez_3_score); // Обратная сортировка
    setSpamData(sortedByRez3Score);
  };

  const handleSortByRez2Score = () => {
    const sortedByRez2Score = [...spamData].sort((a, b) => b.rez_2_score - a.rez_2_score); // Обратная сортировка
    setSpamData(sortedByRez2Score);
  };

  const handleDrawerClickOnlyPredictions = () => {
    setShowPredictionsOnly(!showPredictionsOnly);
  };


  const rmUnusedData = (used) => {
    let states = {
      "setPredictionData": setPredictionData,
      "setPredictionData_single": setPredictionData_single,
      "setPredictionData_single_past": setPredictionData_single_past,

      "setPredictionData_hot": setPredictionData_hot,
      "setPredictionData_hot_past": setPredictionData_hot_past,

      "setPredictionData_gold": setPredictionData_gold,
      "setPredictionData_gold_past": setPredictionData_gold_past,

      "setPredictionData_dg": setPredictionData_dg,
      "setPredictionData_dg_past": setPredictionData_dg_past,

      "setPredictionData_jackpot": setPredictionData_jackpot,

      "setShowPredictionsOnly": setShowPredictionsOnly
    }

    // Remove keys with values matching "used"
    for (let key in states) {
        if (states[key] === used) {
            delete states[key];
        }
    }

    // Set all remaining state functions with empty arrays
    for (let key in states) {
        states[key]([]);
    }
}

  // --------------  Singles After Tomorrow ----------- //
  const handleDrawerClickOnAllLines_single_after_tomorrow = () => {
    const currentDate = new Date(); // Получаем текущую дату
    const twoDaysLater = new Date(currentDate.setDate(currentDate.getDate() + 2)); // Вычисляем дату через два дня
    const formattedDate = formatDate_2(twoDaysLater); // Форматируем дату в нужный вид

    const url = `/get_all_events_singles/${formattedDate}`; // Формируем URL с переменной датой
    rmUnusedData("setPredictionData_single")
    fetchData(url, setPredictionData_single);
  };

  // --------------  HOT After Tomorrow ----------- //
  const handleDrawerClickOnAllLines_hot_after_tomorrow = () => {
    const currentDate = new Date(); // Получаем текущую дату
    const twoDaysLater = new Date(currentDate.setDate(currentDate.getDate() + 2)); // Вычисляем дату через два дня
    const formattedDate = formatDate_2(twoDaysLater); // Форматируем дату в нужный вид
  
    const url = `/prediction_hot_events/${formattedDate}`; // Формируем URL с переменной датой
    rmUnusedData("setPredictionData_hot")
    fetchData(url, setPredictionData_hot);
  };

    // --------------  Gold After Tomorrow ----------- //
  const handleDrawerClickOnAllLines_gold_after_tomorrow = () => {
    const currentDate = new Date(); // Получаем текущую дату
    const twoDaysLater = new Date(currentDate.setDate(currentDate.getDate() + 2)); // Вычисляем дату через два дня
    const formattedDate = formatDate_2(twoDaysLater); // Форматируем дату в нужный вид
  
    const url = `/all_events_gold_cards/${formattedDate}`; // Формируем URL с переменной датой
    rmUnusedData("setPredictionData_gold")
    fetchData(url, setPredictionData_gold);
  };

  // --------------  Double Gold After Tomorrow ----------- //
  const handleDrawerClickOnAllLines_dg_after_tomorrow = () => {
    const currentDate = new Date(); // Получаем текущую дату
    const twoDaysLater = new Date(currentDate.setDate(currentDate.getDate() + 2)); // Вычисляем дату через два дня
    const formattedDate = formatDate_2(twoDaysLater); // Форматируем дату в нужный вид
  
    const url = `/all_events_gouble_gold_cards/${formattedDate}`; // Формируем URL с переменной датой
    rmUnusedData("setPredictionData_dg")
    fetchData(url, setPredictionData_dg);
  };

  // --------------  Jackpot Now ----------- //
  const handleDrawerClickOnAllLines_jackpot_now = () => {
    function getPreviousFriday(date) {
      const dayOfWeek = 5; // Friday
      let lastFriday = new Date(date);
      lastFriday.setDate(lastFriday.getDate() - 0); // Now
  
      while (lastFriday.getDay() !== dayOfWeek) {
        lastFriday.setDate(lastFriday.getDate() - 1);
      }
  
      // Format the date as 'dd_mm_yyyy'
      const day = String(lastFriday.getDate()).padStart(2, '0');
      const month = String(lastFriday.getMonth() + 1).padStart(2, '0'); // January is 0!
      const year = lastFriday.getFullYear();
      return `${day}_${month}_${year}`;
    }
  
    const currentDate = new Date();
    const previousFridayFormatted = getPreviousFriday(currentDate);
  
    const url = `/get_all_events_for_jackpot/${previousFridayFormatted}`;
    rmUnusedData("setPredictionData_jackpot");
    fetchData(url, setPredictionData_jackpot);
  };
  
  const formatDate_2 = (date) => {
    const day = String(date.getDate()).padStart(2, '0'); // Добавляем ведущий ноль, если день < 10
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Добавляем ведущий ноль, если месяц < 10
    const year = date.getFullYear();
    const formattedDate = `${day}_${month}_${year}`;
  
    return formattedDate;
  };

    // --------------  Singles Tomorrow ----------- //
  const handleDrawerClickOnAllLines_single_tomorrow = () => {
    const currentDate = new Date(); // Получаем текущую дату
    const twoDaysLater = new Date(currentDate.setDate(currentDate.getDate() + 1)); // Вычисляем дату через два дня
    const formattedDate = formatDate_1(twoDaysLater); // Форматируем дату в нужный вид
  
    const url = `/get_all_events_singles/${formattedDate}`; // Формируем URL с переменной датой
    rmUnusedData("setPredictionData_single")
    fetchData(url, setPredictionData_single);
  };

  // --------------  HOT Tomorrow ----------- //
  const handleDrawerClickOnAllLines_hot_tomorrow = () => {
    const currentDate = new Date(); // Получаем текущую дату
    const twoDaysLater = new Date(currentDate.setDate(currentDate.getDate() + 1)); // Вычисляем дату через два дня
    const formattedDate = formatDate_1(twoDaysLater); // Форматируем дату в нужный вид
  
    const url = `/prediction_hot_events/${formattedDate}`; // Формируем URL с переменной датой
    rmUnusedData("setPredictionData_hot")
    fetchData(url, setPredictionData_hot);
  };

  // --------------  Gold Tomorrow ----------- //
  const handleDrawerClickOnAllLines_gold_tomorrow = () => {
    const currentDate = new Date(); // Получаем текущую дату
    const twoDaysLater = new Date(currentDate.setDate(currentDate.getDate() + 1)); // Вычисляем дату через два дня
    const formattedDate = formatDate_1(twoDaysLater); // Форматируем дату в нужный вид
  
    const url = `/all_events_gold_cards/${formattedDate}`; // Формируем URL с переменной датой
    rmUnusedData("setPredictionData_gold")
    fetchData(url, setPredictionData_gold);
  };

  // --------------  Double Gold Tomorrow ----------- //
  const handleDrawerClickOnAllLines_dg_tomorrow = () => {
    const currentDate = new Date(); // Получаем текущую дату
    const twoDaysLater = new Date(currentDate.setDate(currentDate.getDate() + 1)); // Вычисляем дату через два дня
    const formattedDate = formatDate_1(twoDaysLater); // Форматируем дату в нужный вид
  
    const url = `/all_events_gouble_gold_cards/${formattedDate}`; // Формируем URL с переменной датой
    rmUnusedData("setPredictionData_dg")
    fetchData(url, setPredictionData_dg);
  };

  // --------------  Jackpot Last one ----------- //
  const handleDrawerClickOnAllLines_jackpot_last_1 = () => {
    function getPreviousFriday(date) {
      const dayOfWeek = 5; // Friday
      let lastFriday = new Date(date);
      lastFriday.setDate(lastFriday.getDate() - 7); // Three weeks ago
  
      while (lastFriday.getDay() !== dayOfWeek) {
        lastFriday.setDate(lastFriday.getDate() - 1);
      }
  
      // Format the date as 'dd_mm_yyyy'
      const day = String(lastFriday.getDate()).padStart(2, '0');
      const month = String(lastFriday.getMonth() + 1).padStart(2, '0'); // January is 0!
      const year = lastFriday.getFullYear();
      return `${day}_${month}_${year}`;
    }
  
    const currentDate = new Date();
    const previousFridayFormatted = getPreviousFriday(currentDate);
  
    const url = `/get_all_events_for_jackpot/${previousFridayFormatted}`;
    rmUnusedData("setPredictionData_jackpot");
    fetchData(url, setPredictionData_jackpot);
  };
  
  const formatDate_1 = (date) => {
    const day = String(date.getDate()).padStart(2, '0'); // Добавляем ведущий ноль, если день < 10
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Добавляем ведущий ноль, если месяц < 10
    const year = date.getFullYear();
    const formattedDate = `${day}_${month}_${year}`;
  
    return formattedDate;
  };

  // --------------  Singles Today ----------- //
  const handleDrawerClickOnAllLines_single_today = () => {
    const currentDate = new Date(); // Получаем текущую дату
    const twoDaysLater = new Date(currentDate.setDate(currentDate.getDate() + 0)); // Вычисляем дату через два дня
    const formattedDate = formatDate_0(twoDaysLater); // Форматируем дату в нужный вид
  
    const url = `/get_all_events_singles/${formattedDate}`; // Формируем URL с переменной датой
    rmUnusedData("setPredictionData_single")
    fetchData(url, setPredictionData_single);
  };

  // --------------  HOT Today ----------- //
  const handleDrawerClickOnAllLines_hot_today = () => {
    const currentDate = new Date(); // Получаем текущую дату
    const twoDaysLater = new Date(currentDate.setDate(currentDate.getDate() + 0)); // Вычисляем дату через два дня
    const formattedDate = formatDate_0(twoDaysLater); // Форматируем дату в нужный вид
  
    const url = `/prediction_hot_events/${formattedDate}`; // Формируем URL с переменной датой
    rmUnusedData("setPredictionData_hot")
    fetchData(url, setPredictionData_hot);
  };

  // --------------  Gold Today ----------- //
  const handleDrawerClickOnAllLines_gold_today = () => {
    const currentDate = new Date(); // Получаем текущую дату
    const twoDaysLater = new Date(currentDate.setDate(currentDate.getDate() + 0)); // Вычисляем дату через два дня
    const formattedDate = formatDate_0(twoDaysLater); // Форматируем дату в нужный вид
  
    const url = `/all_events_gold_cards/${formattedDate}`; // Формируем URL с переменной датой
    rmUnusedData("setPredictionData_gold")
    fetchData(url, setPredictionData_gold);
  };

  // --------------  Double Gold Today ----------- //
  const handleDrawerClickOnAllLines_dg_today = () => {
    const currentDate = new Date(); // Получаем текущую дату
    const twoDaysLater = new Date(currentDate.setDate(currentDate.getDate() + 0)); // Вычисляем дату через два дня
    const formattedDate = formatDate_0(twoDaysLater); // Форматируем дату в нужный вид
  
    const url = `/all_events_gouble_gold_cards/${formattedDate}`; // Формируем URL с переменной датой
    rmUnusedData("setPredictionData_dg")
    fetchData(url, setPredictionData_dg);
  };

  // --------------  Jackpot Last 2 ----------- //
  const handleDrawerClickOnAllLines_jackpot_last_2 = () => {
    function getPreviousFriday(date) {
      const dayOfWeek = 5; // Friday
      let lastFriday = new Date(date);
      lastFriday.setDate(lastFriday.getDate() - 14); // Three weeks ago
  
      while (lastFriday.getDay() !== dayOfWeek) {
        lastFriday.setDate(lastFriday.getDate() - 1);
      }
  
      // Format the date as 'dd_mm_yyyy'
      const day = String(lastFriday.getDate()).padStart(2, '0');
      const month = String(lastFriday.getMonth() + 1).padStart(2, '0'); // January is 0!
      const year = lastFriday.getFullYear();
      return `${day}_${month}_${year}`;
    }
  
    const currentDate = new Date();
    const previousFridayFormatted = getPreviousFriday(currentDate);
  
    const url = `/get_all_events_for_jackpot/${previousFridayFormatted}`;
    rmUnusedData("setPredictionData_jackpot");
    fetchData(url, setPredictionData_jackpot);
  };

  const formatDate_0 = (date) => {
    const day = String(date.getDate()).padStart(2, '0'); // Добавляем ведущий ноль, если день < 10
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Добавляем ведущий ноль, если месяц < 10
    const year = date.getFullYear();
    const formattedDate = `${day}_${month}_${year}`;
  
    return formattedDate;
  };

  // --------------  Singles Yesterday ----------- //
  const handleDrawerClickOnAllLines_single_past_today = () => {
    const currentDate = new Date(); // Получаем текущую дату
    const twoDaysLater = new Date(currentDate.setDate(currentDate.getDate() - 1)); // Вычисляем дату через два дня 
    const formattedDate = formatDate_past(twoDaysLater); // Форматируем дату в нужный вид
  
    const url = `/get_all_events_singles/${formattedDate}`; // Формируем URL с переменной датой
    rmUnusedData("setPredictionData_single_past")
    fetchData(url, setPredictionData_single_past);
  };

  // --------------  Hot Yesterday ----------- //
  const handleDrawerClickOnAllLines_hot_past_today = () => {
    const currentDate = new Date(); // Получаем текущую дату
    const twoDaysLater = new Date(currentDate.setDate(currentDate.getDate() - 1)); // Вычисляем дату через два дня 
    const formattedDate = formatDate_past(twoDaysLater); // Форматируем дату в нужный вид
  
    const url = `/prediction_hot_events/${formattedDate}`; // Формируем URL с переменной датой
    rmUnusedData("setPredictionData_hot_past")
    fetchData(url, setPredictionData_hot_past);
  };
  
  // --------------  Gold Yesterday ----------- //
  const handleDrawerClickOnAllLines_gold_past_today = () => {
    const currentDate = new Date(); // Получаем текущую дату
    const twoDaysLater = new Date(currentDate.setDate(currentDate.getDate() - 1)); // Вычисляем дату через два дня 
    const formattedDate = formatDate_past(twoDaysLater); // Форматируем дату в нужный вид
  
    const url = `/all_events_gold_cards/${formattedDate}`; // Формируем URL с переменной датой
    rmUnusedData("setPredictionData_gold_past")
    fetchData(url, setPredictionData_gold_past);
  };

  // --------------  Double Gold Yesterday ----------- //
  const handleDrawerClickOnAllLines_dg_past_today = () => {
    const currentDate = new Date(); // Получаем текущую дату
    const twoDaysLater = new Date(currentDate.setDate(currentDate.getDate() - 1)); // Вычисляем дату через два дня 
    const formattedDate = formatDate_past(twoDaysLater); // Форматируем дату в нужный вид
  
    const url = `/all_events_gouble_gold_cards/${formattedDate}`; // Формируем URL с переменной датой
    rmUnusedData("setPredictionData_dg_past")
    fetchData(url, setPredictionData_dg_past);
  };

  // --------------  Jackpot Last 3 ----------- //
  const handleDrawerClickOnAllLines_jackpot_last_3 = () => {
    function getPreviousFriday(date) {
      const dayOfWeek = 5; // Friday
      let lastFriday = new Date(date);
      lastFriday.setDate(lastFriday.getDate() - 21); // Three weeks ago
  
      while (lastFriday.getDay() !== dayOfWeek) {
        lastFriday.setDate(lastFriday.getDate() - 1);
      }
  
      // Format the date as 'dd_mm_yyyy'
      const day = String(lastFriday.getDate()).padStart(2, '0');
      const month = String(lastFriday.getMonth() + 1).padStart(2, '0'); // January is 0!
      const year = lastFriday.getFullYear();
      return `${day}_${month}_${year}`;
    }
  
    const currentDate = new Date();
    const previousFridayFormatted = getPreviousFriday(currentDate);
  
    const url = `/get_all_events_for_jackpot/${previousFridayFormatted}`;
    rmUnusedData("setPredictionData_jackpot");
    fetchData(url, setPredictionData_jackpot);
  };

  const formatDate_past = (date) => {
    const day = String(date.getDate()).padStart(2, '0'); // Добавляем ведущий ноль, если день < 10
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Добавляем ведущий ноль, если месяц < 10
    const year = date.getFullYear();
    const formattedDate = `${day}_${month}_${year}`;
  
    return formattedDate;
  };

  // ---------------------------------------- RETURN FUNCTIONS TO VIEW -------------------------------- //
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            TOTAL 93
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
        {['Singles', 'After Tomorrow', 'Tomorrow', 'Today', 'Yesterday'].map((text, index) => (
          <ListItem key={text} disablePadding sx={{ display: 'block' }}>
            {text === 'Singles' ? (
              <ListItemText primary={<strong>{text}</strong>} sx={{ opacity: open ? 1 : 0 }} />
            ) : (
              <ListItemButton
                onClick={
                  text === 'After Tomorrow' ? handleDrawerClickOnAllLines_single_after_tomorrow :
                  text === 'Tomorrow' ? handleDrawerClickOnAllLines_single_tomorrow :
                  text === 'Today' ? handleDrawerClickOnAllLines_single_today :
                  text === 'Yesterday' ? handleDrawerClickOnAllLines_single_past_today :
                  handleDrawerClick
                }
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            )}
          </ListItem>
        ))}
      </List>
      <Divider />
        <List>
          {['Multiply Hot' ,'After Tomorrow', 'Tomorrow', 'Today', 'Yesterday'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
            {text === 'Multiply Hot' ? (
              <ListItemText primary={<strong>{text}</strong>} sx={{ opacity: open ? 1 : 0 }} />
            ) : (
              <ListItemButton
                onClick={
                  text === 'After Tomorrow' ? handleDrawerClickOnAllLines_hot_after_tomorrow :
                  text === 'Tomorrow' ? handleDrawerClickOnAllLines_hot_tomorrow :
                  text === 'Today' ? handleDrawerClickOnAllLines_hot_today :
                  text === 'Yesterday' ? handleDrawerClickOnAllLines_hot_past_today :
                  handleDrawerClick
                }
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
              )}
            </ListItem>
          ))}
        </List>
      <Divider />
        <List>
        {['Multiply Gold', 'After Tomorrow', 'Tomorrow', 'Today', 'Yesterday'].map((text, index) => (
          <ListItem key={text} disablePadding sx={{ display: 'block' }}>
            {text === 'Multiply Gold' ? (
              <ListItemText primary={<strong>{text}</strong>} sx={{ opacity: open ? 1 : 0 }} />
            ) : (
              <ListItemButton
                onClick={
                  text === 'After Tomorrow' ? handleDrawerClickOnAllLines_gold_after_tomorrow :
                  text === 'Tomorrow' ? handleDrawerClickOnAllLines_gold_tomorrow :
                  text === 'Today' ? handleDrawerClickOnAllLines_gold_today :
                  text === 'Yesterday' ? handleDrawerClickOnAllLines_gold_past_today :
                  handleDrawerClick
                }
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            )}
          </ListItem>
        ))}
      </List>
      <Divider />
        <List>
        {['Multiply Double Gold', 'After Tomorrow', 'Tomorrow', 'Today', 'Yesterday'].map((text, index) => (
          <ListItem key={text} disablePadding sx={{ display: 'block' }}>
            {text === 'Multiply Double Gold' ? (
              <ListItemText primary={<strong>{text}</strong>} sx={{ opacity: open ? 1 : 0 }} />
            ) : (
              <ListItemButton
                onClick={
                  text === 'After Tomorrow' ? handleDrawerClickOnAllLines_dg_after_tomorrow :
                  text === 'Tomorrow' ? handleDrawerClickOnAllLines_dg_tomorrow :
                  text === 'Today' ? handleDrawerClickOnAllLines_dg_today :
                  text === 'Yesterday' ? handleDrawerClickOnAllLines_dg_past_today :
                  handleDrawerClick
                }
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            )}
          </ListItem>
        ))}
      </List>
      <Divider />
        <List>
        {['JackPot', 'Today', 'Last One', 'Last Two', 'Last Three'].map((text, index) => (
          <ListItem key={text} disablePadding sx={{ display: 'block' }}>
            {text === 'JackPot' ? (
              <ListItemText primary={<strong>{text}</strong>} sx={{ opacity: open ? 1 : 0 }} />
            ) : (
              <ListItemButton
                onClick={
                  text === 'Today' ? handleDrawerClickOnAllLines_jackpot_now :
                  text === 'Last One' ? handleDrawerClickOnAllLines_jackpot_last_1 :
                  text === 'Last Two' ? handleDrawerClickOnAllLines_jackpot_last_2 :
                  text === 'Last Three' ? handleDrawerClickOnAllLines_jackpot_last_3 :
                  handleDrawerClick
                }
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            )}
          </ListItem>
        ))}
      </List>
      <Divider />
        <List>
        {['All Info',
        // 'After Tomorrow', 'Tomorrow', 'Today',
         'All Past Events'].map((text, index) => (
          <ListItem key={text} disablePadding sx={{ display: 'block' }}>
          {text === 'All Info' ? (
            <ListItemText primary={<strong>{text}</strong>} sx={{ opacity: open ? 1 : 0 }} />
          ) : (
            <ListItemButton
              onClick={
                // text === 'After Tomorrow' ? handleDrawerClickOnAllLines_after_tomorrow :
                // text === 'Tomorrow' ? handleDrawerClickOnAllLines_tomorrow :
                // text === 'Today' ? handleDrawerClickOnAllLines_today :
                handleDrawerClick
              }
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          )}
          </ListItem>
          ))}
        </List>
 


      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <DrawerHeader />
        <Box sx={{ mb: 2 }}>
          <Button variant="outlined" onClick={handleSortById}>
            Sort by ID
          </Button>
          <Button variant="outlined" onClick={handleSortByDate}>
            Sort by Date
          </Button>
          <Button variant="outlined" onClick={handleSortByRez1Score}>
            Sort by Rez1 Score
          </Button>
          <Button variant="outlined" onClick={handleSortByRez3Score}>
            Sort by Rez3 Score
          </Button>
          <Button variant="outlined" onClick={handleSortByRez2Score}>
            Sort by Rez2 Score
          </Button>
          <Button variant="outlined" onClick={handleDrawerClickOnlyPredictions}>
            Only Predictions Events
          </Button>
        </Box>
        <Typography paragraph>
          {spamData.length > 0 && spamData.map((data) => (
            <div key={data.id}>
              <strong>ID :</strong> {data.id} |
              <strong>Date :</strong> {data.date} |
              <strong>Time :</strong> {data.time} |
              <strong>Status :</strong> {data.status} |
              <strong>HT :</strong> {data.ht_score} |
              <strong>FT :</strong> {data.ft_score} |
              <strong>W1 : </strong>{toFixed(data.rez_1_score, 2)} % |
              <strong>Draw : </strong>{toFixed(data.rez_3_score, 2)} % |
              <strong>W2 : </strong>{toFixed(data.rez_2_score, 2)} % |
              <strong>Country :</strong> {data.match_country_name} |
              <strong>League :</strong> {data.league_name} |
              <strong>Team 1 :</strong> {data.localTeam} |
              <strong>Team 2 :</strong> {data.visitorTeam} |
              <strong>Prediction :</strong> {data.prediction}
              <br />
            </div>
          ))}
          {predictionData.length > 0 && predictionData.map((data) => (
            <div key={data.date}>
              <strong>Date :</strong> {data.date} | <strong>Time :</strong> {data.time} | <strong>Country :</strong> {data.country} | <strong>Chempionship :</strong> {data.chempionship} | <strong>Team 1 :</strong> {data.team1} | <strong>Team 2 :</strong> {data.team2} |
              <br />
              <strong>Odd 1 :</strong> {toFixed(data.odd1, 1)} | <strong>Odd X :</strong> {toFixed(data.oddx, 1)} | <strong>Odd 2 :</strong> {toFixed(data.odd2, 1)} |
              <br />
              <strong>Match Events Count :</strong> {data?.match_events_info?.match_events_count}  
              <div>
                <strong>Total More 1.5 :</strong> {data?.match_events_info?.total_more_1_5_percentage} % | <strong>Total Less 1.5 :</strong> {data?.match_events_info?.total_less_1_5_percentage} % | <strong>Total More 2.5 :</strong> {data?.match_events_info?.total_more_2_5_percentage} % | <strong>Total Less 2.5 :</strong> {data?.match_events_info?.total_less_2_5_percentage} % | <strong>Total More 3.5  :</strong> {data?.match_events_info.total_more_3_5_percentage} % | <strong>Total Less 3.5 :</strong> {data?.match_events_info.total_less_3_5_percentage} %
                <br />
                <strong>Win 1 :</strong> {data?.match_events_info?.win_1_percentage} % | <strong>Win 2 :</strong> {data?.match_events_info?.win_2_percentage} % | <strong>Win 1X :</strong> {data?.match_events_info?.win_1X_percentage} % | <strong>Win X2 :</strong> {data?.match_events_info?.win_X2_percentage} % | <strong>Win 1 Fora :</strong> {data?.match_events_info.win_1_fora_percentage} % | <strong>Win 2 Fora :</strong> {data?.match_events_info.win_2_fora_percentage} %
              </div>
              <br />
              <br /><strong>------------------------------------ </strong>
            </div>
          ))}
          {predictionData_dg.length > 0 && predictionData_dg.map((data) => (
              <div key={data.date}>
              <strong>Date :</strong> {data.date} | <strong>Time :</strong> {data.time} 
              <br /><strong>Country :</strong> {data.match_country_name} | <strong>Chempionship :</strong> {data.league_name} 
              <br /><strong>Team 1 :</strong> {data.localTeam} | <strong>Team 2 :</strong> {data.visitorTeam} |
              <br />
              <br /><strong>W1 : </strong>{toFixed(data.rez_1_score)} % | <strong>Draw : </strong>{toFixed(data.rez_3_score)} % | <strong>W2 : </strong>{toFixed(data.rez_2_score)} % |
              <div>
                <br /><strong>Result </strong>   
                <br /><strong>{toFixed(data.localteam_score)} : {toFixed(data.visitorteam_score)} </strong> 
                <br /><strong>Prediction :</strong> {data.prediction} 
              </div>
              <br />
              <br />
            </div>
          ))}
          {predictionData_jackpot.length > 0 && predictionData_jackpot.map((data) => (
              <div key={data.date}>
              <strong>Date :</strong> {data.date} | <strong>Time :</strong> {data.time} 
              <br /><strong>Country :</strong> {data.match_country_name} | <strong>Chempionship :</strong> {data.league_name} 
              <br /><strong>Team 1 :</strong> {data.localTeam} | <strong>Team 2 :</strong> {data.visitorTeam} |
              <br />
              <br /><strong>W1 : </strong>{toFixed(data.rez_1_score)} % | <strong>Draw : </strong>{toFixed(data.rez_3_score)} % | <strong>W2 : </strong>{toFixed(data.rez_2_score)} % |
              <div>
                <br /><strong>Result </strong>   
                <br /><strong>{toFixed(data.localteam_score)} : {toFixed(data.visitorteam_score)} </strong> 
                <br /><strong>Prediction :</strong> {data.prediction} 
              </div>
              <br />
              <br />
            </div>
          ))}
          {predictionData_gold.length > 0 && predictionData_gold.map((data) => (
              <div key={data.date}>
              <strong>Date :</strong> {data.date} | <strong>Time :</strong> {data.time} 
              <br /><strong>Country :</strong> {data.match_country_name} | <strong>Chempionship :</strong> {data.league_name} 
              <br /><strong>Team 1 :</strong> {data.localTeam} | <strong>Team 2 :</strong> {data.visitorTeam} |
              <br />
              <br /><strong>W1 : </strong>{toFixed(data.rez_1_score)} % | <strong>Draw : </strong>{toFixed(data.rez_3_score)} % | <strong>W2 : </strong>{toFixed(data.rez_2_score)} % |
              <div>
                <br /><strong>Result </strong>   
                <br /><strong>{toFixed(data.localteam_score)} : {toFixed(data.visitorteam_score)} </strong> 
                <br /><strong>Prediction :</strong> {data.prediction} 
              </div>
              <br />
              <br />
            </div>
          ))}
          {predictionData_hot.length > 0 && predictionData_hot.map((data) => (
              <div key={data.date}>
              <strong>Date :</strong> {data.date} | <strong>Time :</strong> {data.time} 
              <br /><strong>Country :</strong> {data.match_country_name} | <strong>Chempionship :</strong> {data.league_name} 
              <br /><strong>Team 1 :</strong> {data.localTeam} | <strong>Team 2 :</strong> {data.visitorTeam} |
              <br />
              <br /><strong>W1 : </strong>{toFixed(data.rez_1_score)} % | <strong>Draw : </strong>{toFixed(data.rez_3_score)} % | <strong>W2 : </strong>{toFixed(data.rez_2_score)} % |
              <div>
                <br /><strong>Result </strong>   
                <br /><strong>{toFixed(data.localteam_score)} : {toFixed(data.visitorteam_score)} </strong> 
                <br /><strong>Prediction :</strong> {data.prediction} 
              </div>
              <br />
              <br />
            </div>
          ))}
          {predictionData_single.length > 0 && predictionData_single.map((data) => (
              <div key={data.date}>
              <strong>Date :</strong> {data.date} | <strong>Time :</strong> {data.time} 
              <br /><strong>Country :</strong> {data.match_country_name} | <strong>Chempionship :</strong> {data.league_name} 
              <br /><strong>Team 1 :</strong> {data.localTeam} | <strong>Team 2 :</strong> {data.visitorTeam} |
              <br />
              <br /><strong>W1 : </strong>{toFixed(data.rez_1_score)} % | <strong>Draw : </strong>{toFixed(data.rez_3_score)} % | <strong>W2 : </strong>{toFixed(data.rez_2_score)} % |
              <div>
                <br /><strong>Result </strong>   
                <br /><strong>{toFixed(data.localteam_score)} : {toFixed(data.visitorteam_score)} </strong> 
                <br /><strong>Prediction :</strong> {data.prediction} 
              </div>
              <br />
              <br />
            </div>
          ))}
          {predictionData_dg_past.length > 0 && predictionData_dg_past.map((data) => (
              <div key={data.date}>
              <strong>Date :</strong> {data.date} | <strong>Time :</strong> {data.time} 
              <br /><strong>Country :</strong> {data.match_country_name} | <strong>Chempionship :</strong> {data.league_name} 
              <br /><strong>Team 1 :</strong> {data.localTeam} | <strong>Team 2 :</strong> {data.visitorTeam} |
              <br />
              <br /><strong>W1 : </strong>{toFixed(data.rez_1_score)} % | <strong>Draw : </strong>{toFixed(data.rez_3_score)} % | <strong>W2 : </strong>{toFixed(data.rez_2_score)} % |
              <div>
                <br /><strong>Result </strong>   
                <br /><strong>{toFixed(data.localteam_score)} : {toFixed(data.visitorteam_score)} </strong> 
                <br /><strong>Prediction :</strong> {data.prediction} 
              </div>
              <br />
              <br />
            </div>
          ))}
          {predictionData_gold_past.length > 0 && predictionData_gold_past.map((data) => (
            <div key={data.date}>
            <strong>Date :</strong> {data.date} | <strong>Time :</strong> {data.time} 
            <br /><strong>Country :</strong> {data.match_country_name} | <strong>Chempionship :</strong> {data.league_name} 
            <br /><strong>Team 1 :</strong> {data.localTeam} | <strong>Team 2 :</strong> {data.visitorTeam} |
            <br />
            <br /><strong>W1 : </strong>{toFixed(data.rez_1_score)} % | <strong>Draw : </strong>{toFixed(data.rez_3_score)} % | <strong>W2 : </strong>{toFixed(data.rez_2_score)} % |
            <div>
              <br /><strong>Result </strong>   
              <br /><strong>{toFixed(data.localteam_score)} : {toFixed(data.visitorteam_score)} </strong> 
              <br /><strong>Prediction :</strong> {data.prediction} 
            </div>
            <br />
            <br />
          </div>
          ))}
           {predictionData_hot_past.length > 0 && predictionData_hot_past.map((data) => (
              <div key={data.date}>
              <strong>Date :</strong> {data.date} | <strong>Time :</strong> {data.time} 
              <br /><strong>Country :</strong> {data.match_country_name} | <strong>Chempionship :</strong> {data.league_name} 
              <br /><strong>Team 1 :</strong> {data.localTeam} | <strong>Team 2 :</strong> {data.visitorTeam} |
              <br />
              <br /><strong>W1 : </strong>{toFixed(data.rez_1_score)} % | <strong>Draw : </strong>{toFixed(data.rez_3_score)} % | <strong>W2 : </strong>{toFixed(data.rez_2_score)} % |
              <div>
                <br /><strong>Result </strong>   
                <br /><strong>{toFixed(data.localteam_score)} : {toFixed(data.visitorteam_score)} </strong> 
                <br /><strong>Prediction :</strong> {data.prediction} 
              </div>
              <br />
              <br />
            </div>
          ))}
          {predictionData_single_past.length > 0 && predictionData_single_past.map((data) => (
            <div key={data.date}>
            <strong>Date :</strong> {data.date} | <strong>Time :</strong> {data.time} 
            <br /><strong>Country :</strong> {data.match_country_name} | <strong>Chempionship :</strong> {data.league_name} 
            <br /><strong>Team 1 :</strong> {data.localTeam} | <strong>Team 2 :</strong> {data.visitorTeam} |
            <br />
            <br /><strong>W1 : </strong>{toFixed(data.rez_1_score)} % | <strong>Draw : </strong>{toFixed(data.rez_3_score)} % | <strong>W2 : </strong>{toFixed(data.rez_2_score)} % |
            <div>
              <br /><strong>Result </strong>   
              <br /><strong>{toFixed(data.localteam_score)} : {toFixed(data.visitorteam_score)} </strong> 
              <br /><strong>Prediction :</strong> {data.prediction} 
            </div>
            <br />
            <br />
          </div>
        ))}
        </Typography>
      </Box>
    </Box>
  );
}
