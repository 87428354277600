import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card_statistics from './Card_statistics'; // Используйте ваш текущий компонент Card для деталей матча
// import './Card_statistics.css'; // Подключение стилей

function StatisticsPage() {
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://total93admin.com/get_events_statistic_past_90`, {
          headers: {
            Authorization: 'Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd', // Ваш токен авторизации
          },
        });
        setMatches(response.data); // Предполагается, что API возвращает массив матчей
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Предполагаем, что статистика всех матчей одинакова, берем данные из первого матча
  const generalStatistics = matches[0]?.statistic_results;

  return (
    <div className="statistics-page">
      {/* <h1>Statistics</h1> */}
      {generalStatistics && (
        <>
        <div className="general-statistics">
          <h4>Last 90 Days General Statistics:</h4>
          <ul>
            <li>Total Matches: {generalStatistics.total_all}</li>
            <li>Clear Matches: {generalStatistics.total_clear}</li>
            <li>Postponed: {generalStatistics.total_postponed}</li>
            <li>Plus: {generalStatistics.plus}, Minus: {generalStatistics.minus}</li>
            <li>Success Percent: {generalStatistics.percent_plus}</li>
            <li>Average odd by single event: 1.35 </li>
            <li>Average odd by multiple 3 events: 2.45 </li>
          </ul>
        </div>
        <div className="specific-statistics">
          <h4>Specific Statistics by Predictions:</h4>
          <ul>
            <li>W1 - Plus: {generalStatistics.total_plus_W1}, Minus: {generalStatistics.total_minus_W1}, Success: {generalStatistics.percent_plus_W1}</li>
            <li>W2 - Plus: {generalStatistics.total_plus_W2}, Minus: {generalStatistics.total_minus_W2}, Success: {generalStatistics.percent_plus_W2}</li>
            <li>1X - Plus: {generalStatistics.total_plus_1X}, Minus: {generalStatistics.total_minus_1X}, Success: {generalStatistics.percent_plus_1X}</li>
            <li>X2 - Plus: {generalStatistics.total_plus_X2}, Minus: {generalStatistics.total_minus_X2}, Succes: {generalStatistics.percent_plus_X2}</li>
          </ul>
        </div>
      </>
      )}
      {matches.length ? (
        matches.map((match, index) => (
          <Card_statistics key={index} data={match} /> // Используйте Card для отображения информации по каждому матчу
        ))
      ) : (
        <p>Loading data...</p>
      )}
    </div>
  );
}

export default StatisticsPage;
