import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from './Card';
import './Card.css';

function RecentFriday() {
  const [fridayData, setFridayData] = useState(null);

  useEffect(() => {
    const fetchFridayData = async () => {
      const today = new Date();
      // Adjust the date to the most recent Friday
      const dayOfWeek = today.getDay();
      const daysToLastFriday = dayOfWeek === 5 ? 0 : dayOfWeek + 2;
      const lastFriday = new Date(today.setDate(today.getDate() - daysToLastFriday));

      // Format the date as 'DD_MM_YYYY'
      const formattedDate = `${String(lastFriday.getDate()).padStart(2, '0')}_${String(lastFriday.getMonth() + 1).padStart(2, '0')}_${lastFriday.getFullYear()}`;

      try {
        const response = await axios.get(`https://total93admin.com/get_all_events_for_jackpot/${formattedDate}`, {
          headers: {
            Authorization: 'Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd', // Replace with your authorization token
          },
        });

        setFridayData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchFridayData();
  }, []);

  return (
    <div>
      {Array.isArray(fridayData) ? (
        fridayData.map((event) => (
          <Card key={event.id} data={event} />
        ))
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default RecentFriday;
