import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import Card from './Card_gold';
import './Card.css'; // Import styles

function Yesterday() {
  const [yesterdayData, setYesterdayData] = useState(null);

  useEffect(() => {
    const fetchYesterdayData = async () => {
      const yesterdayDate = moment().add(0, 'days').format('DD_MM_YYYY');

      try {
        const response = await axios.get(`https://total93admin.com/all_events_gold_cards/${yesterdayDate}`, {
          headers: {
            Authorization: 'Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd', // Replace with your authorization token
          },
        });

        setYesterdayData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchYesterdayData();
  }, []);

  return (
    <div>
      {Array.isArray(yesterdayData) ? (
        yesterdayData.map((event) => (
          <Card key={event.id} data={event} />
        ))
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Yesterday;
