import React from 'react';
import './Card_statistics.css'; // Создайте и подключите стили для карточек

const Card_statistics = ({ data }) => {
  return (
    <div className="card">
      <h3>{data.localTeam} vs {data.visitorTeam}</h3>
      {/* <p>Date: {data.date}, Time: {data.time} Status: {data.status}, Full Time Score: {data.ft_score} Prediction: {data.prediction}, Result: {data.prediction_result}</p> */}
      <p>Date: {data.date}, Time: {data.time}</p>
      <p>Status: {data.status}, Full Time Score: {data.ft_score}</p>
      <p>Prediction: {data.prediction}</p>
      <p>Result: {data.prediction_result}</p>
    </div>
  );
};

export default Card_statistics;
