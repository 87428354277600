import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from './Card';
import './Card.css';

function LastWeekFriday() {
  const [lastWeekFridayData, setLastWeekFridayData] = useState(null);

  useEffect(() => {
    const fetchLastWeekFridayData = async () => {
      const today = new Date();
      // Adjust the date to the Friday of the previous week
      const dayOfWeek = today.getDay();
      const daysToLastWeekFriday = dayOfWeek === 5 ? 14 : dayOfWeek + 11;
      const lastWeekFriday = new Date(today.setDate(today.getDate() - daysToLastWeekFriday));

      // Format the date as 'DD_MM_YYYY'
      const formattedDate = `${String(lastWeekFriday.getDate()).padStart(2, '0')}_${String(lastWeekFriday.getMonth() + 1).padStart(2, '0')}_${lastWeekFriday.getFullYear()}`;

      try {
        const response = await axios.get(`https://total93admin.com/get_all_events_for_jackpot/${formattedDate}`, {
          headers: {
            Authorization: 'Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd', // Replace with your authorization token
          },
        });

        setLastWeekFridayData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchLastWeekFridayData();
  }, []);

  return (
    <div>
      {Array.isArray(lastWeekFridayData) ? (
        lastWeekFridayData.map((event) => (
          <Card key={event.id} data={event} />
        ))
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default LastWeekFriday;
