import React, { useState, useEffect } from 'react';
// import moment from 'moment';
import axios from 'axios';
import Card from './Card_totals';
import './Card.css'; // Import styles

function Yesterday() {
  const [yesterdayData, setYesterdayData] = useState(null);

  useEffect(() => {
    const fetchYesterdayData = async () => {
      try {
        const response = await axios.post(`https://total93admin.com/get_prediction_list`, 
          {
            type: 'single',  // Передаем параметр type
          },
          {
            headers: {
              Authorization: 'Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd', // Replace with your authorization token
            },
            params: {
              page: 1,
              page_size: 10,  // Получаем больше данных для последующей фильтрации
            },
          }
        );

        const events = response.data.events;

        // Фильтрация матчей по статусу "Finished" и выбор последних 10 матчей
        const filteredEvents = events
          .filter((event) => event.status === 'Not started')
          .slice(0, 10); // Берем последние 10 матчей

        setYesterdayData(filteredEvents);
      } catch (error) {
        console.error(error);
      }
    };

    fetchYesterdayData();
  }, []);

  return (
    <div>
      {Array.isArray(yesterdayData) ? (
        yesterdayData.map((event) => (
          <Card key={event.id} data={event} />
        ))
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Yesterday;
