import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from './Card_totals';
import './Card.css'; // Import styles

function Yesterday() {
  const [yesterdayData, setYesterdayData] = useState(null);

  useEffect(() => {
    const fetchYesterdayData = async () => {
      try {
        const response = await axios.post(`https://total93admin.com/get_prediction_list`, 
          {
            type: 'single',  // Передаем параметр type
          },
          {
            headers: {
              Authorization: 'Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd', // Replace with your authorization token
            },
            params: {
              page: 1,
              page_size: 10,  // Получаем больше данных для последующей фильтрации
            },
          }
        );

        const events = response.data.events;

        // Фильтрация матчей по статусу "Not started" и выбор последних 1 матчей
        const filteredEvents = events
          .filter((event) => event.status === 'Not started')
          .slice(0, 1); // Берем последний матч

        setYesterdayData(filteredEvents);
      } catch (error) {
        console.error(error);
      }
    };

    fetchYesterdayData();

    // Динамически добавляем скрипт
    const script = document.createElement('script');
    script.src = "https://betfury.bet/sources/d329485ce.js";
    script.async = true;
    document.body.appendChild(script);

    // Очистка при размонтировании компонента
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {Array.isArray(yesterdayData) ? (
        yesterdayData.map((event) => (
          <Card key={event.id} data={event} />
        ))
      ) : (
        <p>Loading...</p>
      )}

      {/* Добавляем контейнер для баннера с измененной шириной и центрированием */}
      <div style={{ textAlign: 'center', marginTop: '10px', marginLeft: '25px' }}>
        <div style={{ width: '400px', marginLeft: '25px' }}>
          <script type="text/javascript" src="https://betfury.bet/sources/d329485ce.js"></script>
        </div>
      </div>
    </div>
  );
}

export default Yesterday;
