import React, { useState } from 'react';

const PaymentPage = () => {
    const [redirectUrl, setRedirectUrl] = useState('');
    const [loading, setLoading] = useState(false);

    const handlePayment = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://changellywidget.banxa.com', {
                method: 'GET',
                // Добавьте необходимые параметры для оплаты
                body: JSON.stringify({
                    expires: '1716664579',
                    id: '898634b6-97d4-4c4c-a17e-ed63bb89ec5c',
                    oid: '3552ab69081cc8fcca2657c30c5d9dad',
                    signature: '2aec07c4f934d21c28f25953b32e2a8f9835e67a775070d2c049c72dd81e1658',
                    paymentMethod: 'card'
                })
            });
            const data = await response.json();
            // Перенаправление на страницу оплаты
            setRedirectUrl(data.redirectUrl);
        } catch (error) {
            console.error('Ошибка при обработке оплаты:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h1>Оплата заказа</h1>
            {loading ? (
                <p>Подождите, идет обработка оплаты...</p>
            ) : (
                <button onClick={handlePayment}>Оплатить картой</button>
            )}
            {redirectUrl && <iframe src={redirectUrl} width="100%" height="600px" title="Payment Widget"></iframe>}
        </div>
    );
};

export default PaymentPage;
